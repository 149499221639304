<template>
  <div class="d-flex-column main align-center mw-700">
    <el-card>
      <h3>Clearance Form</h3>
      <p>
        Thank you for requesting a clearance, you're almost there!
        We are just assessing your clearance and it is
        currently pending approval. You will hear from us again shortly.
      </p>
      <p>
        You will hear from us again shortly via email.
        Keep and eye on your email address "<a>{{email}}</a>" for an update on the status of your clearance.
        Your next email will include the steps to completing your registration.
        Take a moment to check and update your
        <a class="a" v-bind:href="'/profile/edit/'+id">profile details</a> now.
      </p>
    </el-card>
    <el-button type="primary" class="mt-1 button w-90"
               @click="$router.push({ name: 'profile' })"
    >
      Go to profile
    </el-button>
  </div>
</template>

<script>
export default {
  name: "ClearanceComplete",
  props: {},
  created() {},
  data() {
    return {
      email: null,
      id: null,
    };
  },
  methods: {
    getCurrentMemberEmail() {
      this.$set(this, 'email', this.$store.getters["registration/getCurrentMemberEmail"]);
    },
    getCurrentMemberId() {
      this.$set(this, 'id', this.$store.getters["registration/getCurrentMemberId"]);
    },
  },
  computed: {},
  mounted() {
    this.getCurrentMemberEmail();
    this.getCurrentMemberId();
  },
};
</script>

<style scoped lang="scss">
.a {
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  };
}
</style>
